import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { Grid, Paper } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import theme from "../theme"

const errorImgProps = {
  maxWidth: "350px",
  minHeight: "100px",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
}

const titleProps = {
  letterSpacing: ".3rem",
  fontSize: "1.5rem",
  color: theme.palette.secondary.main,
}

const paperProps = {
  padding: theme.spacing(1),
  textAlign: "center",
}

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      query {
        confusedBitmoji: file(relativePath: { eq: "bitmoji/confused.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 375) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const errorImg = data.confusedBitmoji.childImageSharp.fluid
      return (
        <Layout>
          <SEO title="Oops! 404 Error - Not Found" />
          <section id="404">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Paper style={paperProps} className="p-5" elevation={0}>
                <h1
                  className="p-3 text-uppercase bold90Spaced"
                  style={titleProps}
                >
                  Oh no
                </h1>
                <Img
                  fluid={errorImg}
                  fadeIn={true}
                  alt="confused tj"
                  style={errorImgProps}
                />
                <h3 className="p-2 text-uppercase boldSpacedPadded">
                  This page does not exist.
                </h3>
                <Link to="/">
                  <h3
                    className="p-2 text-uppercase boldSpacedPadded"
                    style={{ color: theme.palette.secondary.main }}
                  >
                    Let me take you back to a page that works
                  </h3>
                </Link>
                <h3 className="p-2 text-uppercase boldSpacedPadded">
                  (Think you might have found a bug? Let's connect)
                </h3>
              </Paper>
            </Grid>
          </section>
        </Layout>
      )
    }}
  />
)

export default NotFoundPage
